import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// MUI
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

// components
import ChoiceButton from "./ChoiceButton";

// text
import utils from "../utils/utils";

const ItemsDetails = ({ title, price, promo, frequency, options, from }) => {
  const text = utils.Text();
  const offers = text.offers[title];
  const { lang } = useSelector((state) => state.lang);
  const [sx, setSx] = useState("");

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: ["Basic", "أساسي"].includes(title) ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(4),
    color: ["Basic", "أساسي"].includes(title)
      ? "#fff"
      : theme.palette.text.secondary,
    borderRadius: "1.25rem",
    boxShadow: "0 0 1.25rem 0 rgba(0, 0, 0, 0.1)",
  }));

  useEffect(() => {
    if (lang === "ar") {
      if (["Basic", "أساسي"].includes(title)) {
        setSx("font-alt h2 promo2");
      } else {
        setSx("font-alt h2 promo");
      }
    } else {
      setSx("font-alt h2 strikethrough");
    }
  }, [lang, title]);

  return (
    <Grid xs={12} sm={5} md={2.6} className="m-3">
      <Item>
        <h3 className="text-muted">{title}</h3>
        <h6>{from}</h6>
        <span className="font-alt h2">{price}</span>
        {/*<h5 className="text-danger font-alt">{promo}</h5>*/}
        <p className="mb-1">{frequency}</p>
        <hr />
        {options.map((option, index) => {
          return (
            <p className="mb-3" key={index}>
              <img
                src={
                  offers.includes(option)
                    ? "/assets/img/no.png"
                    : "/assets/img/ok.svg"
                }
                alt=""
                className="me-2"
                width="20"
                height="20"
              />
              {option}
            </p>
          );
        })}
        <ChoiceButton title={title} />
      </Item>
    </Grid>
  );
};

export default ItemsDetails;
