import { createSlice } from '@reduxjs/toolkit';

export const modalSlice = createSlice({
    name: "modal",
    initialState: {
        status: false
    },
    reducers: {
        setModal: (state, action) => {
            return {
                ...state,
                status: action.payload
            }
        }
    }
})

export const { setModal } = modalSlice.actions

export default modalSlice.reducer