// text
import utils from '../utils/utils';

const Square = () => {
    const { squareMsg } = utils.Text();

    return (
        <aside className="text-center bg-gradient-primary-to-secondary">
            <div className="container px-5">
                <div className="row gx-5 justify-content-center">
                    <div className="col-xl-9">
                        <div className="h2 fs-1 text-white mb-4">{squareMsg}</div>
                        <img src="/assets/img/square.png" alt="square-logo" style={{ height: "3rem" }} />
                        <div className="text-white fw-bold mt-2">THE SQUARE</div>
                    </div>
                </div>
            </div>
        </aside>
    );
}

export default Square;