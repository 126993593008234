import { useDispatch } from "react-redux";

//redux
import { setModal } from "../redux/modal";
import { setSubject } from "../redux/form";

// text
import utils from "../utils/utils";

const ChoiceButton = ({ title }) => {
  const dispatch = useDispatch();
  const { priceBtn } = utils.Text();

  const handleOpen = (e) => {
    e.preventDefault();
    dispatch(setSubject(title));
    dispatch(setModal(true));
  };

  return (
    <div className="d-flex">
      <button
        className="btn btn-primary w-100 rounded-pill px-3 py-2"
        onClick={handleOpen}
      >
        {priceBtn}
      </button>
    </div>
  );
};

export default ChoiceButton;
