// text
import utils from "../utils/utils";

const Description = () => {
    const text = utils.Text();

    return (
        <section id="features">
            <div className="container px-3">
                <div className="row gx-5 align-items-center">
                    <div className="col-lg-8 order-lg-1 mb-5 mb-lg-0">
                        <div className="container-fluid px-3">
                            <div className="row gx-5">
                                <div className="col-md-6 mb-5">
                                    <div className="text-center">
                                        <i className="bi-phone icon-feature text-gradient d-block mb-3"></i>
                                        <h3 className="font-alt">{text.DescTitle1}</h3>
                                        <p className="text-muted mb-0">{text.Desc11}<b>{text.Desc12}</b></p>
                                        <p className="text-muted mb-0"> {text.Desc13}</p>
                                        <p className="text-muted mb-0">{text.Desc14}</p>
                                        <p className="text-muted mb-0">{text.Desc15}</p>
                                    </div>
                                </div>
                                <div className="col-md-6 mb-5">
                                    <div className="text-center">
                                        <i className="bi-aspect-ratio icon-feature text-gradient d-block mb-3"></i>
                                        <h3 className="font-alt">{text.DescTitle2}</h3>
                                        <p className="text-muted mb-0">{text.Desc21} <b>{text.Desc22}</b></p>
                                        <p className="text-muted mb-0">{text.Desc23}</p>
                                        <p className="text-muted mb-0">{text.Desc24} <b>{text.Desc25}</b></p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6 mb-5 mb-md-0">
                                    <div className="text-center">
                                        <i className="bi-piggy-bank icon-feature text-gradient d-block mb-3"></i>
                                        <h3 className="font-alt">{text.DescTitle3}</h3>
                                        <p className="text-muted mb-0">{text.Desc31} <b>80%</b> {text.Desc32}</p>
                                        <p className="text-muted mb-0">{text.Desc33}</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="text-center">
                                        <i className="bi-lightbulb icon-feature text-gradient d-block mb-3"></i>
                                        <h3 className="font-alt">{text.DescTitle4}</h3>
                                        <p className="text-muted mb-0">{text.Desc41}</p>
                                        <p className="text-muted mb-0">{text.Desc42}<b>{text.Desc43}</b> {text.Desc44}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 order-lg-0">
                        <div className="features-device-mockup">
                            <svg className="circle" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
                                <defs>
                                    <linearGradient id="circleGradient" gradientTransform="rotate(45)">
                                        <stop className="gradient-start-color" offset="0%"></stop>
                                        <stop className="gradient-end-color" offset="100%"></stop>
                                    </linearGradient>
                                </defs>
                                <circle cx="50" cy="50" r="50"></circle>
                            </svg>
                            <svg className="shape-1 d-none d-sm-block" viewBox="0 0 240.83 240.83" xmlns="http://www.w3.org/2000/svg">
                                <rect x="-32.54" y="78.39" width="305.92" height="84.05" rx="42.03" transform="translate(120.42 -49.88) rotate(45)"></rect>
                                <rect x="-32.54" y="78.39" width="305.92" height="84.05" rx="42.03" transform="translate(-49.88 120.42) rotate(-45)"></rect></svg>
                            <svg className="shape-2 d-none d-sm-block" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"><circle cx="50" cy="50" r="50"></circle></svg>
                            <div className="device-wrapper">
                                <div className="device" data-device="iPhoneX" data-orientation="portrait" data-color="black">
                                    <div className="screen bg-black">
                                        <img className="w-100 h-100" src="/assets/img/square2.jpg" alt="square-interface" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Description;