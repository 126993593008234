import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

//redux
import { setModal } from '../redux/modal';
import { setLang } from '../redux/lang';

//MIUI
import NativeSelect from '@mui/material/NativeSelect';

// scripts
import utils from '../utils/utils';

const NavBar = () => {
    const dispatch = useDispatch();
    const { lang } = useSelector(state => state.lang);
    const { nav, nav1, nav2, nav3, navBtn } = utils.Text();

    const handleOpen = (e) => {
        e.preventDefault();
        dispatch(setModal(true));
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-light fixed-top shadow-sm" id="mainNav">
            <div className="container px-5">
                <a className="navbar-brand" href="#page-top"><img src="/assets/img/interakt_web.png" width="180px" alt="interakt-web logo" /></a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                    {nav}
                    <i className="bi-list"></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarResponsive">
                    <ul className="navbar-nav ms-auto me-4 my-3 my-lg-0">
                        <li className="nav-item"><a className="nav-link me-lg-3" href="#features">{nav1}</a></li>
                        <li className="nav-item"><a className="nav-link me-lg-3" href="#pricing">{nav3}</a></li>
                        <li className="nav-item"><a className="nav-link me-lg-3" href="#communication">{nav2}</a></li>
                    </ul>
                    <button className="btn btn-primary rounded-pill px-3 mb-2 mb-lg-0">
                        <span className="d-flex align-items-center" onClick={handleOpen}>
                            <i className="bi-chat-text-fill me-2"></i>
                            <span className="small">{navBtn}</span>
                        </span>
                    </button>
                    <NativeSelect
                        className={lang === "ar" ? "me-5" : "ms-5"}
                        value={lang}
                        onChange={(e) => dispatch(setLang(e.target.value))}
                        inputProps={{
                            name: 'lang',
                            id: 'uncontrolled-native',
                        }}
                    >
                        <option value="fr">FR</option>
                        <option value="en">ENG</option>
                        <option value="ar">ARA</option>
                    </NativeSelect>
                </div>
            </div>
        </nav>
    );
};

export default NavBar;