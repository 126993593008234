import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import './css/styles.css';

//components
import PageWrapper from './features/PageWrapper';
import MainPage from './views/MainPage';

function App() {
  return (
    <Router>
      <Switch>
        <PageWrapper>
          <Route exact={true} path="/" component={MainPage} />
        </PageWrapper>
      </Switch>
    </Router>
  );
}

export default App;
