import { useMemo } from "react";
import { useSelector } from "react-redux";

// Text
import data from "../data/text.json";

// CFA countries
const BF = require('../data/BF.json');
const BJ = require('../data/BJ.json');
const CI = require('../data/CI.json');
const ML = require('../data/ML.json');
const NE = require('../data/NE.json');
const SN = require('../data/SN.json');
const TG = require('../data/TG.json');

const CFA = [TG, ML, BF, BJ, CI, NE, SN];

const pointInPolygon = (polygon, point) => {
    let nvert = polygon.length;
    let c = false;
    for (let i = 0, j = nvert - 1; i < nvert; j = i++) {
        let pI = polygon[i];
        let pJ = polygon[j];
        if (((pI[1] > point[1]) !== (pJ[1] > point[1])) &&
            (point[0] < (pJ[0] - pI[0]) * (point[1] - pI[1]) / (pJ[1] - pI[1]) + pI[0])) {
            c = !c;
        }
    }
    return c;
};

const utils = {
    // script depends on lang function
    Text: () => {
        const { lang } = useSelector((state) => state.lang);
        const text = useMemo(() => {
            const item = ""
            switch (lang) {
                case "fr":
                    return data.find((x) => x.fr)["fr"];
                case "en":
                    return data.find((x) => x.en)["en"];
                case "ar":
                    return data.find((x) => x.ar)["ar"];
            };
            return item;
        }, [lang]);

        return text;
    },

    reverseGeolocation: (lat, lng) => {
        const point = [lng, lat];
        for (const country of CFA) {
            const coords = country.geometry.coordinates;
            const found = (country.geometry.type === 'Polygon' &&
                pointInPolygon(coords[0], point) &&
                coords.length === 1) ||
                (country.geometry.type === 'MultiPolygon' &&
                    coords.some(polygonCoords =>
                        pointInPolygon(polygonCoords[0], point) &&
                        polygonCoords.slice(1).every(holeCoords =>
                            !pointInPolygon(holeCoords, point))));

            if (found) return true;
        }
        throw new Error('Location outside of supported countries.');
    },
};

export default utils;