import React, { useState, useEffect } from 'react';

// MUI
import Grid from '@mui/material/Grid';
// import Chip from '@mui/material/Chip';

// components
import ItemsDetails from '../components/ItemsDetails';

// text
import utils from '../utils/utils';

const Price = () => {

    const { price, priceTitle, priceDesc, from } = utils.Text();
    const [continent, setCont] = useState(false);

    const success = (position) => {
        const { latitude, longitude } = position.coords;
        if (latitude && longitude) {
            const location = utils.reverseGeolocation(latitude, longitude);
            location && setCont(location);
        }
    };

    const error = () => {
        console.log(`Unable to retrieve your location`);
    };

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(success, error);
    }, []);

    return (
        <section id="pricing">
            <div className="container px-3">
                <h2 className="display-4 lh-1 px-5 mb-4 w-100">{priceTitle} {/*<Chip label="Promo -2O% off" color='error' sx={{ fontWeight: 'bold' }} />*/}</h2>
                <Grid container className="justify-content-center">
                    {
                        price.map((offer, index) => {
                            return (
                                <ItemsDetails
                                    key={index}
                                    title={offer.title}
                                    from={from}
                                    price={continent ? offer.price[1] : offer.price[0]}
                                    promo={continent ? offer.promo[1] : offer.promo[0]}
                                    frequency={offer.frequency}
                                    options={offer.options}
                                />
                            )
                        })
                    }
                </Grid>
                <div className='px-5 mt-3 fs-p'>
                    {priceDesc.map((desc, index) =>
                        <p className="text-muted mb-0" key={index}>{desc}</p>
                    )}
                </div>
            </div>
        </section>
    );
}

export default Price;