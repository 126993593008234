// scripts
import utils from "../utils/utils";

const Footer = () => {
  const year = new Date().getFullYear();
  const { copy, policy, mentions, cgv } = utils.Text();

  return (
    <footer className="bg-black text-center py-5">
      <div className="container px-5">
        <div className="d-flex flex-row justify-content-center">
          <a
            href="https://www.facebook.com/people/In_terakt/100093120452626/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/assets/img/facebook.png"
              className="mx-3 mb-3"
              width="32px"
              alt="facebook"
            />
          </a>
          <a
            href="https://www.instagram.com/in_terakt/"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src="/assets/img/instagram.svg"
              className="mx-3 mb-3"
              width="32px"
              alt="instagram"
            />
          </a>
          <a href="https://wa.me/33766808241" target="_blank" rel="noreferrer">
            <img
              src="/assets/img/whatsapp.svg"
              className="mx-3 mb-3"
              width="32px"
              alt="whatsapp"
            />
          </a>
        </div>
        <hr className="bg-white" />
        <div className="text-white-50 small">
          <a href="#!">{policy}</a>
          <span className="mx-1">&middot;</span>
          <a href="#!">{mentions}</a>
          <span className="mx-1">&middot;</span>
          <a href="#!">{cgv}</a>
          <div className="mb-2">
            &copy; In-ter'akt
            <sup>
              <i>web</i>
            </sup>{" "}
            {year}. {copy}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
