import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

// components
import Contact from "../components/Contact";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

const PageWrapper = ({ children }) => {

    const { lang } = useSelector(state => state.lang);

    return (
        <div className={lang === "ar" && "rtl"}>
            <Helmet
                htmlAttributes={lang}
            />
            <NavBar />
            {children}
            <Footer />
            <Contact />
        </div>
    );
}

export default PageWrapper;