import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";

//reducers
import langReducer from "./lang";
import modalReducer from "./modal";
import formReducer from "./form";

const reducers = combineReducers({
  lang: langReducer,
  modal: modalReducer,
  form: formReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["lang"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

export default store;
