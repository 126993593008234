import { createSlice } from "@reduxjs/toolkit";

export const formSlice = createSlice({
  name: "form",
  initialState: {
    subject: "Demo",
  },
  reducers: {
    setSubject: (state, action) => {
      return {
        ...state,
        subject: action.payload,
      };
    },
  },
});

export const { setSubject } = formSlice.actions;

export default formSlice.reducer;
