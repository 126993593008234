// text
import utils from "../utils/utils";

const QRcode = () => {
    const { qrTitle, qrDesc1, qrDesc2, qrDesc3, qrDesc4, qrDesc5, qrDesc6 } = utils.Text();

    return (
        <section className="bg-light">
            <div className="container px-5">
                <div className="row gx-5 align-items-center justify-content-center justify-content-lg-between">
                    <div className="col-12 col-lg-5">
                        <h2 className="display-4 lh-1 mb-4">{qrTitle}</h2>
                        <p className="lead fw-normal text-muted mb-5 mb-lg-0">{qrDesc1} <b>{qrDesc2}</b> {qrDesc3}</p>
                        <p className="lead fw-normal text-muted mb-5 mb-lg-0">{qrDesc4} <b>{qrDesc5}</b> {qrDesc6}</p>
                    </div>
                    <div className="col-sm-8 col-md-6">
                        <div className="px-5 px-sm-0">
                            <img className="img-fluid rounded-circle" src="/assets/img/qr-code.png" alt="qr-code" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default QRcode;