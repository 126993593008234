import { createSlice } from '@reduxjs/toolkit';

export const langSlice = createSlice({
    name: "lang",
    initialState: {
        lang: "fr"
    },
    reducers: {
        setLang: (state, action) => {
            return {
                ...state,
                lang: action.payload
            }
        }
    }
})

export const { setLang } = langSlice.actions

export default langSlice.reducer