// text
import utils from '../utils/utils';

const Partner = () => {
    const { PartnersTitle, PartnersDesc1, PartnersDesc2 } = utils.Text();

    return (
        <section className="bg-gradient-primary-to-secondary" id="communication">
            <div className="container px-5">
                <h2 className="text-center text-white mb-4">{PartnersTitle}</h2>
                <h3 className="text-center text-white font-alt mb-4">{PartnersDesc1}</h3>
                <p className="lead fw-normal text-center text-white mb-5 mb-lg-0">{PartnersDesc2}</p>
                <div className="d-flex flex-column flex-lg-row align-items-center justify-content-center mt-5">
                    <a className="me-lg-3 mb-4 mb-lg-0" href="#!"><img className="app-badge" src="/assets/img/hi-events.png" alt="HI-Events" /></a>
                </div>
            </div>
        </section>
    );
}

export default Partner;