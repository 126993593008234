import React, { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import emailjs from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";

//redux
import { setModal } from "../redux/modal";

// MUI
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import NativeSelect from "@mui/material/NativeSelect";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";

// scripts
import utils from "../utils/utils";
import { useEffect } from "react";

/*
const style = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  transition: "transform 0.3s ease-out",
  boxShadow: 24,
}; 
*/

const Contact = () => {
  const { status } = useSelector((state) => state.modal);
  const { subject } = useSelector((state) => state.form);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);
  const dispatch = useDispatch();
  const text = utils.Text();
  const captchaRef = useRef(null);

  const handleClose = (e) => {
    e.preventDefault();
    dispatch(setModal(false));
    setSuccess(false);
  };

  const validationSchema = yup.object({
    firstname: yup.string("Enter your fistname").required("Name is required"),
    lastname: yup
      .string("Enter your lastname")
      .required("Lastname is required"),
    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    phone: yup
      .number("Enter your phone number")
      .required("Phone number is required"),
    country: yup.string("Enter your country").required("Country is required"),
    subject: yup.string("Enter your subject").required("Subject is required"),
    message: yup
      .string("Enter your message")
      .max(500, "Message should be of maximum 500 characters length")
      .required("Message is required"),
  });

  useEffect(() => {
    formik.setFieldValue("subject", subject);
  }, [subject]);

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      subject: subject,
      message: "",
    },
    validationSchema: validationSchema,
    onSubmit: async () => {
      setLoading(true);
      const captchaValue = captchaRef.current.getValue();
      captchaRef.current.reset();
      const params = {
        ...formik.values,
        "g-recaptcha-response": captchaValue,
      };

      try {
        await emailjs.send(
          "service_interakt",
          "template_interakt",
          params,
          process.env.REACT_APP_EMAILJS_USERID,
          "g-recaptcha-response"
        );
        setSuccess(true);
        setError(false);
      } catch (error) {
        console.error(error.text);
        setError(true);
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <React.Fragment>
      <Modal
        open={status}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        sx={{
          overflowX: "hidden",
          overflowY: "auto",
        }}
      >
        <Fade in={status}>
          <Box>
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header bg-gradient-primary-to-secondary px-4 py-3">
                  <h5
                    className="modal-title font-alt text-white"
                    id="feedbackModalLabel"
                  >
                    {text.formTitle}
                  </h5>
                  <button
                    className="btn-close btn-close-white"
                    type="button"
                    onClick={handleClose}
                  ></button>
                </div>
                <div className="modal-body border-0 p-4">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="form-floating mb-3">
                      <TextField
                        type="text"
                        name="firstname"
                        label={text.formName}
                        variant="outlined"
                        fullWidth
                        required
                        value={formik.values.firstname}
                        onChange={(e) =>
                          formik.setFieldValue("firstname", e.target.value)
                        }
                        error={
                          formik.touched.firstname &&
                          Boolean(formik.errors.firstname)
                        }
                        helperText={
                          formik.touched.firstname && formik.errors.firstname
                        }
                      />
                    </div>
                    <div className="form-floating mb-3">
                      <TextField
                        type="text"
                        name="lastname"
                        label={text.formLastName}
                        variant="outlined"
                        fullWidth
                        required
                        value={formik.values.lastname}
                        onChange={(e) =>
                          formik.setFieldValue("lastname", e.target.value)
                        }
                        error={
                          formik.touched.lastname &&
                          Boolean(formik.errors.lastname)
                        }
                        helperText={
                          formik.touched.lastname && formik.errors.lastname
                        }
                      />
                    </div>
                    <div className="form-floating mb-3">
                      <TextField
                        type="email"
                        name="email"
                        label={text.formEmail}
                        variant="outlined"
                        fullWidth
                        required
                        value={formik.values.email}
                        onChange={(e) =>
                          formik.setFieldValue("email", e.target.value)
                        }
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={formik.touched.email && formik.errors.email}
                      />
                    </div>
                    <div className="form-floating mb-3">
                      <TextField
                        type="tel"
                        name="phone"
                        label={text.formNumber}
                        variant="outlined"
                        fullWidth
                        required
                        value={formik.values.phone}
                        onChange={(e) =>
                          formik.setFieldValue("phone", e.target.value)
                        }
                        error={
                          formik.touched.phone && Boolean(formik.errors.phone)
                        }
                        helperText={formik.touched.phone && formik.errors.phone}
                      />
                    </div>
                    <div className="form-floating mb-3">
                      <TextField
                        type="text"
                        name="country"
                        label={text.formCountry}
                        variant="outlined"
                        fullWidth
                        required
                        value={formik.values.country}
                        onChange={(e) =>
                          formik.setFieldValue("country", e.target.value)
                        }
                        error={
                          formik.touched.country &&
                          Boolean(formik.errors.country)
                        }
                        helperText={
                          formik.touched.country && formik.errors.country
                        }
                      />
                    </div>
                    <div className="form-floating mb-3">
                      <NativeSelect
                        fullWidth
                        inputProps={{
                          name: "subject",
                          id: "uncontrolled-native",
                        }}
                        value={formik.values.subject}
                        onChange={(e) =>
                          formik.setFieldValue("subject", e.target.value)
                        }
                        error={
                          formik.touched.subject &&
                          Boolean(formik.errors.subject)
                        }
                        helperText={
                          formik.touched.subject && formik.errors.subject
                        }
                      >
                        <option value="Demo">{text.formSelect1}</option>
                        <option value="Devis">{text.formSelect2}</option>
                        <option value="Starter">{text.formSelect4}</option>
                        <option value="Basic">{text.formSelect5}</option>
                        <option value="Standard">{text.formSelect6}</option>
                        <option value="Plus">{text.formSelect7}</option>
                        <option value="Autre">{text.formSelect3}</option>
                      </NativeSelect>
                    </div>
                    <div className="form-floating mb-3">
                      <TextField
                        type="text"
                        label={text.formMessage}
                        name="message"
                        variant="outlined"
                        placeholder={text.formMessagePlaceholder}
                        fullWidth
                        multiline
                        minRows={5}
                        required
                        value={formik.values.message}
                        onChange={(e) =>
                          formik.setFieldValue("message", e.target.value)
                        }
                        error={
                          formik.touched.message &&
                          Boolean(formik.errors.message)
                        }
                        helperText={
                          formik.touched.message && formik.errors.message
                        }
                      />
                    </div>
                    {success && (
                      <div className="text-center text-success mb-3">
                        {text.formSuccess}
                      </div>
                    )}
                    {error && (
                      <div className="text-center text-danger mb-3">
                        {text.formError}
                      </div>
                    )}
                    <div className="d-flex justify-content-center mb-3">
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                        ref={captchaRef}
                      />
                    </div>
                    <Box sx={{ position: "relative", width: "100%" }}>
                      <div className="d-grid">
                        <button
                          className="btn btn-primary rounded-pill btn-lg"
                          type="submit"
                          disabled={loading}
                        >
                          {text.formSubmit}
                        </button>
                        {loading && (
                          <CircularProgress
                            size={20}
                            sx={{
                              color: "white",
                              position: "absolute",
                              top: "75%",
                              left: "50%",
                              marginTop: "-22px",
                              marginLeft: "-12px",
                            }}
                          />
                        )}
                      </div>
                    </Box>
                  </form>
                </div>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </React.Fragment>
  );
};

export default Contact;
