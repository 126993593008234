//components
import Header from '../components/Header';
import Square from '../components/Square';
import Description from '../components/Description';
import QRcode from "../components/QRcode";
import Price from "../features/Price";
import Partner from "../components/Partner";

const MainPage = () => {

    return (
        <>
            <Header />
            <Square />
            <Description />
            <QRcode />
            <Price />
            <Partner />
        </>
    );
}

export default MainPage;